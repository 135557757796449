import { styled } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../../assets/SafeHealthBanner.png'
import { selectNetworkSettings } from '../../../pages/network/model'
import { paths } from '../../../pages/paths'

export const LogoLinkHome = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  return (
    <>
      {networkSettings && (
        <Link aria-label="home" to={paths.app.dashboard()}>
          <LogoImage />
        </Link>
      )}
    </>
  )
}

export const LogoImage = (props?: { className?: string }) => {
  const networkSettings = useSelector(selectNetworkSettings)
  return (
    <Logo
      src={
        networkSettings?.logoRectangleDark
          ? networkSettings.logoRectangleDark
          : logo
      }
      className={props?.className}
      alt="HealthCheck"
    />
  )
}

export const Logo = styled('img')({
  maxHeight: 74,
  maxWidth: 180,
})
